import React from "react"
import LaunchTestButton from "../LaunchTestButton"

const SpeakingModule = (props) => (
    <section className="lrws-section-1">
        <div className="container">
        <div className="listening-wrapper mt-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="listening-image-wrapper">
                    <img src={require('../../images/speaking-image.png')} alt="" />
                  </div>
                </div>
                <div className="col-md-8" >
                  <div className="listening-content-wrapper">
                    <h3 className="content-title">
                     Speaking
                    </h3>
                    <ul>
                      <li>
                        <span>
                          <img src={require('../../images/duration.png')} alt="" />
                          <strong>Duration: : </strong> 60 min  (4-5 min, 2 min & 4-5 min)
                        </span>
                      </li>
                    </ul>
                    <p className="note">The speaking part has three tasks</p>
                    <div className="listening-sections-wrapper">
                      <p className="content-sub-title">Sections</p>
                      <div className="content-sections">
                        <div>Task 1</div>
                        <p>General questions about yourself and familiar topics, such as home, family, work,studies and interests. Lasts for about 4-5 minutes.</p>
                      </div>
                      <div className="content-sections">
                        <div>Task 2</div>
                        <p>Talk about the given topic. You get one minute for preparation and will speak for up to two minutes. A few questions will be asked on the topic.</p>
                      </div>
                      <div className="content-sections">
                        <div>Task 3</div>
                        <p>More questions on the topic in task 2. You will be able to discuss abstract ideas and issues in depth. Lasts for about 4-5 minutes.</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-5">
                      <LaunchTestButton testId = {props.testId} courseId = {props.courseId} classList="trymock-btn btn" text="TRY MOCK TEST" /> 
                      <div className="free-label">FREE!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
)

export default SpeakingModule
