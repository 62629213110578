import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Banner from "../../components/Common/Banner"
import Menu from "../../components/IELTS_Academic/Menu"
import RecomendedCourses from "../../components/IELTS_Academic/RecomendedCourses"
import SpeakingModule from "../../components/IELTS_Academic/SpeakingModule"

const testId = '5ca2f1f21692d45f2a23b589c6a19333'
const courseId = '695431377ee45b963cebc7fc57eb7669'

const Speaking = () => (
  <Layout>
    <SEO title="IELTS Academic - Speaking" />
    <main>
        <Banner />
        <Menu />
        <SpeakingModule testId={testId} courseId={courseId} />
        <RecomendedCourses testId={testId} courseId={courseId} />
    </main>
  </Layout>
)

export default Speaking
